$(function() {
  if ($('#alert').length == 0) {
    return;
  }

  if(!window.location.pathname.includes("control_panel")){
    displayAlert(1);
  }

  $(document).on('click', '#alert .close a', closeAlert);
  $(document).on('click', '#alert a.next', nextAlert);
  $(document).on('click', '#alert a.prev', prevAlert);


  function nextAlert() {
    if ($(this).hasClass("disabled")) return;

    var index = $(this).parents(".alert_block").data("index");
    displayAlert(index + 1);
  }

  function prevAlert() {
    if ($(this).hasClass("disabled")) return;

    var index = $(this).parents(".alert_block").data("index");
    displayAlert(index - 1);
  }


  function closeAlert() {
    var alert_id = $(this).parents(".alert_block").data("alertId");
    var alert_type = $(this).parents(".alert_block").data("alertType");
    var currentId = $(this).parents(".alert_block").data("index");
    $.post("/alerts/" + alert_type + "/" + alert_id + "/close", { theme: "2015" }, function(html) {
      if (html) {
        $('#alert').replaceWith(html);
        displayAlert(currentId);
      } else {
        $('#alert').slideUp();
      }
    });
  }

  function displayAlert(index) {
    var count = $('#alert .alert_block').length;
    if (index >= count) { // snap to last
      index = count;
    }
    var selectedBlock = $('#alert .alert_block[data-index=' + index + ']');
    var alert_id = selectedBlock.data("alertId");
    var alert_type = selectedBlock.data("alertType");
    var alreadyViewed = selectedBlock.data("alreadyViewed");

    $('.alert_block').hide();
    selectedBlock.show();

    selectedBlock.find(".next").toggleClass("disabled", selectedBlock.next().length == 0);
    selectedBlock.find(".prev").toggleClass("disabled", selectedBlock.prev().length == 0);

    if (!alreadyViewed) {
      $.post("/alerts/viewed", { id: alert_id, alert_type: alert_type });
    }
  }
});

