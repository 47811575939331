$(function() {
  setupMobileMenu();
});

function setupMobileMenu() {
  var isTouchDevice = 'ontouchstart' in document.documentElement;
  var eventName = isTouchDevice ? "touchstart" : "click";
  var menus = $('#mobile_menu, #your_account_menu');
  var yourAccount = $('#your_account_menu');
  $(document).on(eventName, '.mobile_menu_open, .mobile_menu_close', function(e) {
    e.stopPropagation();
    e.preventDefault();
    var menu = $('#mobile_menu');
    if(menu.is(':visible')) {
      $("main, header#miniheader").toggleClass("blur");
      menu.hide();
    } else {
      $("main, header#miniheader").toggleClass("blur");
      menu.show();
    }
  });
}
