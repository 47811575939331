(function() {
  var placeholders = initPlaceholderData();
  var previousPlaceholderKey = "";

  if (placeholders.length > 0) {
    handleResize();
    $(window).resize(handleResize);
  }

  function handleResize() {
    var width = $(window).width();
    var key = width < 768 ? "mobile-placeholder" : "main-placeholder";
    if (key == previousPlaceholderKey) return;
    previousPlaceholderKey = key;

    placeholders.forEach(function(elt) {
      elt.attr("placeholder", elt.data(key));
    });
  }

  function initPlaceholderData() {
    var placeholders = []
    $('input[data-mobile-placeholder]').each(function() {
      var mainText = $(this).attr("placeholder");
      var mobileText = $(this).data("mobile-placeholder");
      $(this).data("main-placeholder", mainText);
      placeholders.push($(this));
    });

    return placeholders;
  }
})();
