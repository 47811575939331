import "jquery-ui/ui/widgets/autocomplete";
export default function initRenewals(renewableDomains, input, classNames) {
  $.ui.autocomplete.prototype._renderItem = function(ul, item) {
    return $("<li></li>")
      .append($("<a>").text(item.label))
      .appendTo(ul);
  };
  input.autocomplete({
    minLength: 0,
    source: renewableDomains,
    matchContains: true,
    multiple: true,
    select: function(e, ui) {
      $(".renew").addClass("loading");
      document.location.href = "/renew/check_domains?domains=" + ui.item.value;
    },
    open: function(e, ui) {
      $(".ui-autocomplete").addClass(classNames);
    }
  }).focus(function(e) {
    var input = $(e.target);
    input.autocomplete("search", input.val());
  });
}
