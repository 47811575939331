import "jquery-ui/themes/base/menu.css";
import "jquery-ui/themes/base/autocomplete.css";
import "jquery-ui/themes/base/datepicker.css";
import "jquery-ui/themes/base/theme.css";
import "application/styles/application.sass"
import "application/common/alerts";
import "application/common/cartItemCount";
import "application/common/flash";
import "application/common/menu";
import "application/common/mobilemenu";
import "application/common/responsive";
import "application/common/polyfill/arrayFrom";
import "application/common/polyfill/endsWith";
import "application/common/polyfill/startsWith";
import initRenewals from "checkout/renew/renew";
import $ from 'jquery'

// Really ugly, but required for qtip
window.$ = window.jQuery = $;
require("qtip2");

export {
  $,
  initRenewals
}
